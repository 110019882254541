<template>
  <span class="picker">
    <select
      class="flix-form-control"
      v-model="date[0]"
      @change.prevent="changeTime"
    >
      <option
        v-for="(index, hour) in new Array(24)"
        :value="hour"
        :key="hour"
        >{{ getNullsBefore(hour) }}</option
      >
    </select>
    :
    <select
      class="flix-form-control"
      v-model="date[1]"
      @change.prevent="changeTime"
    >
      <option v-for="minute in getTimer()" :value="minute" :key="minute">{{
        getNullsBefore(minute)
      }}</option>
    </select>
  </span>
</template>
<script>
export default {
  components: {},
  props: {
    time: String,
    callback: Function,
    range: {
      type: [Number, String],
      default() {
        return 15;
      }
    }
  },
  data() {
    return {
      date: this.getDate(this.time)
    };
  },
  computed: {},
  mounted() {},
  methods: {
    getTimer() {
      const min = [];
      for (var i = 0; i < 60; i += this.range * 1) {
        min.push(i);
      }
      return min;
    },
    changeTime() {
      this.callback(
        this.getNullsBefore(this.date[0]) +
          ":" +
          this.getNullsBefore(this.date[1])
      );
    },
    getDate(time) {
      time = time.split(":");
      time[0] = time[0] * 1;
      time[1] = time[1] * 1;
      return time;
    },
    getNullsBefore(i) {
      i = i * 1;
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    }
  }
};
</script>
<style lang="sass" scoped></style>
